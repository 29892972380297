<template>
  <div class="container-fluid">
    <div class="row row-no-gutters">
      <div class="col-xs-2">
        <h2>REGRAS DEFINIDAS PARA LANÇAMENTO DOS CRÉDITOS IPTU</h2>
        <p>
          1. Os créditos gerados poderão abater até 50% (cinquenta por cento) do IPTU, referente ao imóvel que o tomador indicar (Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          2. Não é necessário que o dono dos créditos tenha qualquer vinculo com imóveis. (§1º do Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          3. Os créditos serão tributados em príodo estabelecido em regulamento. (§2º do Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          4. Os créditos somente poderão ser usados para abater IPTU de imóveis que não possuam débitos, em exercícios anteriores ou em atraso. 
          (§2º do Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          5. Os créditos de pessoa física, que possuam débitos de IPTU, que sejam lançadas junto ao IPTU, ficam com sua situação suspensa até que se regularize
          sua situação. (Inciso I, §3º do Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          6. O crédito tem validade de 15 (quinze) meses. (§4º do Art. 3º da Lei Complementar 456/2012)
        </p>
        <p>
          7. O tomador de serviços, exclusivamente pessoa física, tem direito a 30% (trinta por cento) do valor do ISSQN efetivamente recolhido. (Caput do Art. 2º da Lei Complementar 456/2012)
        </p>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "HomeCredito",
  components: {},
  data() {
    return {
      
    };
  },
  created() {

  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>