<template>
    <div>
        <div class="">
            <p style="text-align: right">
                <Button label="Adicionar Inscrição" icon="pi pi-plus" severity="success" class="mr-2" style="width: 20%"
                    @click="mostrarModal" :disabled="dataLimiteAdd"/>
            </p>
            <DataTable paginator showGridlines ref="dt" class="p-datatable-customers" responsiveLayout="scroll" dataKey="id"
                :loading="loading" :value="inscricoes" :rows="15" :rowHover="true">

                <template #header>
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span class="text-xl text-900 font-bold">Inscrições Imobiliárias</span>
                    </div>
                </template>

                <template #loading>
                    <div class="mt-6"> Carregando Inscrições... </div>
                </template>

                <Column header="Ações">
                    <template #body="slotProps">
                        <div class="d-flex justify-content-center">
                            <Button type="button" icon="pi pi-times" title="Exluir Inscrição" @click="removerInscricao(slotProps.data.id)"
                                class="p-button-grid p-button-sm" severity="danger"/>
                        </div>
                    </template>
                </Column>

                <Column field="sequencia_compensar" header="Ordem" style="width: 10%">
                    <template #body="slotProps">
                        {{ slotProps.data.sequencia_compensar }}
                    </template>
                </Column>
                <Column field="nome_geral" header="Nome" style="width: 60%">
                    <template #body="slotProps">
                        {{ slotProps.data.nome_geral }}
                    </template>
                </Column>
                <Column field="ano" header="Ano" style="width: 10%">
                    <template #body="slotProps">
                        {{ slotProps.data.ano }}
                    </template>
                </Column>
                <Column field="inscricao_municipal" header="Inscrição" style="width: 10%" aria-required="true">
                    <template #body="slotProps">
                        {{ slotProps.data.inscricao_municipal }}
                    </template>
                </Column>
            </DataTable>
        </div>

        <Dialog v-model:visible="adicionarInscricao" :style="{ width: '650px' }" header="Adicionar Inscrição" :modal="true">
            <div class="field">
                <label for="inscricao">Inscrição</label><br />
                <InputText id="inscricao" name="inscricao" v-model="inscricao" style=" width: 25em "
                    @blur="pesquisarInscricao()" />
                <Button icon="pi pi-search" @click="pesquisarInscricao()" />
            </div>
            <Card v-if="consultarInscricao && consultarInscricao.inscricaoMunicipal">
                <template #title> Inscrição: {{ consultarInscricao.inscricaoMunicipal }} </template>
                <template #subtitle><span style="font-weight: bold;">{{ consultarInscricao.numeroDocumentoJuridico }} - {{
                    consultarInscricao.nome }}</span></template>
                <template #content>
                    <p class="m-0" style="font-weight: bold;">
                        Endereço: {{ consultarInscricao.endereco }}
                    </p>
                </template>
            </Card>

            <template #footer>
                <Button label="Cancelar" icon="pi pi-times" text @click="fecharModal" />
                <Button label="Salvar" icon="pi pi-check" text @click="salvar" :disabled="loading" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import {
    CreditoService
} from "../../services";
import LoginService from "@/services/login.service";
import Util from '../../utils/util';

export default {
    data() {
        return {
            loading: false,
            adicionarInscricao: false,
            inscricoes: [],
            consultarInscricao: null,
            inscricao: "",
            idPessoa: null,
            idUsuario: null,
            dataLimiteAdd: false
        };
    },
    mounted() {
        this.consultarInscricao = null,
            this.inscricao = null;
        this.idPessoa = this.currentUser?.usuario?.pessoa.id;
        this.idUsuario = this.currentUser?.usuario?.id;
        this.pesquisar();
        this.verificaDataLimiteAdd();
    },
    computed: {
        idCliente() {
            return this.$store.getters.getIdCliente;
        },
        currentUser() {
            return LoginService.currentUserValue;
        },
        disableAdd() {
            return this.dataLimiteAdd === false;
        },
    },
    methods: {
        processando(show) {
            this.loading = show || false;
            this.$store.dispatch("SET_ENABLE_SPINNER", this.loading);
        },
        mostrarModal() {
            this.inscricao = null;
            this.consultarInscricao = null;
            this.adicionarInscricao = true;
        },
        fecharModal() {
            this.adicionarInscricao = false;
        },
        refreshExtratos() {
            this.pesquisar();
        },
        async pesquisarInscricao() {
            this.processando(true);
            this.consultarInscricao = null;
            if (!this.inscricao || !this.inscricao.trim()) {
                this.alerta("error", "Preencha o campo inscrição");
                this.inscricao.focus;
            } else {
                this.consultarInscricao = await CreditoService.findInscricao(
                    this.idCliente,
                    this.inscricao.trim()
                );
                if (!this.consultarInscricao) {
                    this.alerta(
                        "error",
                        "Inscrição não encontrada ou com débitos com o municipio"
                    );
                }
            }
            this.processando(false);
        },
        async removerInscricao(id){
            this.processando(true);
            const retorno = await CreditoService.removerInscricao(id);
            if(retorno === 'OK'){
                this.alerta("success", "Inscrição removida com sucesso!");
                await this.pesquisar();
            }else{
                this.alerta("error", "Erro ao remover inscrição!");
            }
            this.processando(false);
        },
        async salvar() {
            try {
                this.processando(true);
                if (!this.consultarInscricao || !this.consultarInscricao.idContribuinte) {
                    this.alerta("error", "Informe uma inscrição válida!");
                    //this.inscricao.focus;
                    this.processando(false);
                    return;
                }

                for (let index = 0; index < this.inscricoes.length; index++) {
                    const element = this.inscricoes[index]['inscricao_municipal'];
                    if (element == this.inscricao.trim()) {
                        this.alerta("error", "Essa inscrição já foi inserida");
                        this.processando(false);
                        return false;
                    }
                }


                const ultimaSequencia = await CreditoService.ultimaSequencia(
                    this.idPessoa
                );
                const data = new Date();
                const retorno = await CreditoService.inserirInscricao({
                    ano: data.getFullYear(),
                    id_contribuinte_cadastro: this.consultarInscricao.idContribuinte,
                    data_cadastro: data,
                    id_pessoa: this.idPessoa,
                    valor_imposto: 0,
                    version: 1,
                    idCliente: this.idCliente,
                    idUsuario: this.idUsuario,
                    sequencia_compensar: ultimaSequencia[0] ?? 1,
                });

                if (retorno) {
                    this.alerta("success", "Inscrição inserida com sucesso!");
                    this.fecharModal();
                    this.pesquisar();
                    this.inscricao = null;
                }
                this.processando(false);
            } catch (errorMessage) {
                this.alerta("error", "Houve um problema ao inserir seu registro!");
                this.processando(false);
            }

        },

        async pesquisar() {
            try {
                this.inscricoes = await CreditoService.findAll(this.idPessoa);
            } catch (errorMessage) {
                console.log(errorMessage);
                this.alerta("error", "Houve um problema ao listar o Inscrições!");
            }
        },

        async verificaDataLimiteAdd() {
            try {
                this.dataLimiteAdd = await CreditoService.verificaDataLimiteAdd(this.idCliente);
                if(!Util.validarDataMenor(this.dataLimiteAdd, Util.diaAtual("T"))){
                    
                    this.dataLimiteAdd = true;
                    this.alerta("error", "Período indisponível para indicação de imóveis!");
                }else{
                    this.dataLimiteAdd = false;   
                }

            } catch (errorMessage) {
                console.log(errorMessage);
                this.alerta("error", "Período indisponível para indicação de imóveis!");
            }
        },

        alerta(type, msg) {
            this.$toast.add({
                severity: type,
                summary: msg,
                life: 3000,
            });
        },
        formatarValor(value) {
            return value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        },
    },
};
</script>
