<template>
  <div class="container">
    <div class="login">
      <br />
      <br />
      <br />
      <div class="form-title" v-if="this.usuario">
        <span class="form-title">Bem-vindo(a) {{ this.usuario.pessoa.nomeGeral }}, Login:
          {{ this.usuario.pessoa.numeroDocumentoJuridico }}</span>
        <br />
        <span class="form-subtitle">Preencha os campos abaixo para registrar sua nova Senha.</span>
      </div>

      <div>
        <div class="d-flex justify-content-center">
          <div class="col-md-8 mb-3">
            <span class="p-fluid">
              <InputText id="senha" type="password" placeholder="Senha" autocomplete="off" tabindex="1"
                class="inputLogin" v-model="senha" maxlength="50" />
            </span>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-center">
          <div class="col-md-8 mb-3">
            <span class="p-fluid">
              <InputText id="contraSenha" type="password" placeholder="Repetir Senha" autocomplete="off" tabindex="2"
                class="inputLogin" maxlength="50" v-model="contraSenha" />
            </span>
          </div>
        </div>
      </div>
      <div>
        <br />
        <Button label="Registrar" @click="handleRecuperarSenha()" class="p-button-primary p-button-danger btnEntrar" />
      </div>
      <br />
      <div>
        <Button label="Página Inicial" @click="paginaInicial()" class="p-button-primary btnEntrar" />
      </div>

    </div>
  </div>
</template>
<script>
import LoginService from "@/services/login.service";
import RecuperaSenha from "@/models/acesso/RecuperaSenha";

export default {
  name: "recuperarSenha",

  data() {
    return {
      erro: null,
      loading: false,
      chave: null,
      senha: null,
      contraSenha: null,
      usuario: null
    };
  },
  created() {
    this.loading = true;
    this.chave = this.$route.query.chave;
    this.currentUser = LoginService.currentUserValue;
    if (this.currentUser) {
      LoginService.logOut();
      location.reload();
    }

    if (!this.chave || this.chave == "undefined") {
      this.$router.push("/");
      return;
    }
    LoginService.buscarUsuarioPorChave(this.chave)
      .then(
        response => {
          this.usuario = response.data;
        },
        error => {
          this.$root.$emit("alert:warn", error.response.data);
          this.$router.push("/");
        }
      )
      .then(() => {
        this.loading = false;
      });
  },
  mounted() { },
  methods: {
    paginaInicial() {
      this.$router.push("/");
    },
    handleRecuperarSenha() {
      this.loading = true;
      if (!this.senha || !this.contraSenha) {
        this.$root.$emit(
          "alert:warn",
          "É necesário informar a Senha e Repetir a Senha!"
        );
        return;
      } else if (this.senha != this.contraSenha) {
        this.$root.$emit(
          "alert:warn",
          "A senha de Repetição não confere com a nova senha informada!"
        );
        return;
      }
      this.recuperaSenha = new RecuperaSenha();
      this.recuperaSenha.chave = this.chave;
      this.recuperaSenha.senha = this.senha;

      LoginService.novaSenha(this.recuperaSenha)
        .then(
          response => {
            console.log(response);
            this.$root.$emit(
              "alert:success",
              "Nova Senha registrada com sucesso!"
            );
            this.$router.push("/");
          },
          error => {
            this.$root.$emit("alert:warn", error.response.data);
          }
        )
        .then(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
.login {
  width: 100%;
  text-align: center;
  padding-top: 45px;
}

.login .form-title {
  margin-bottom: 20px;
  color: black;
  font-size: 19px;
  font-weight: 400 !important;
}

.login .form-subtitle {
  color: black;
  font-size: 17px;
  font-weight: 300 !important;
  padding-left: 10px;
}

.inputLogin {
  width: 40% !important;
}

.btnEntrar {
  width: 30%;
}

.login a {
  color: black !important;
  cursor: pointer;
}

.login .copyright {
  text-align: center;
  margin: 10px auto 30px 0;
  padding: 10px;
  color: black;
  font-size: 13px;
}
</style>

